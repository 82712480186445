import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { IBooking } from '@/services/types/booking/details';
import { IInsuranceBundle } from '@/services/types/booking/insuranceBundle';
import { IBookingService } from '@/services/types/booking/services';
import { EProtections, EServiceTypes } from '@/services/types/core/protections';
import { IQuote } from '@/services/types/core/quotes';
import { formatCurrency } from '@/utility/currency';
import { getIntl } from '@/utility/i18n';

import { getListingGeneratorRules, getListingMileageRules } from '../listing/houseRules';
import { getOwnerName } from '../listing/owner';
import { getListingPriceType, PriceType } from '../listing/page';
import { getBookingOrQuoteTotal } from './total';

const calculateTotalPriceForCategory = (
  services1: IBookingService[],
  services2: IBookingService[] | undefined,
  category: string,
) => {
  const getTotal = (services?: IBookingService[]) => {
    return services?.find(service => service.category === category)?.total || 0;
  };

  return getTotal(services1) + getTotal(services2);
};

interface IUsageRules {
  title: string;
  subTitle?: string;
  tiers?: string[];
  free?: boolean;
}

interface ICost {
  name: string;
  description?: string;
  value: string;
}

interface ICostDetails {
  costs?: ICost[];
  total: string | null;
}

const mapDataToCostDetails = ({
  data,
  formattedTotal,
  ownerName = '',
  mileageRules,
  generatorRules,
  priceType,
  showTripCredits,
  isStay,
}: {
  data?: IBooking | IQuote | null;
  formattedTotal: string | null;
  ownerName?: string;
  mileageRules: IUsageRules | null;
  generatorRules: IUsageRules | null;
  priceType: PriceType;
  showTripCredits: boolean;
  isStay?: boolean;
}): ICostDetails | null => {
  if (!data) {
    return null;
  }

  const {
    calculated_day_price,
    rental_amount_item,
    duration,
    delivery_item,
    insurance_bundle,
    service_fee,
    owner_fees,
    tax,
    items,
    presentment_currency,
    services,
    discount_code,
    discount_code_amount,
    renter_credits_applied,
    siblings,
  } = data;

  let siblingQuotesOrBookingsTotals = null;

  if ('sibling_bookings_totals' in data) {
    siblingQuotesOrBookingsTotals = data?.sibling_bookings_totals;
  } else if ('sibling_quotes_totals' in data) {
    siblingQuotesOrBookingsTotals = data?.sibling_quotes_totals;
  }

  const currencyFormat = { currency: presentment_currency, digits: 2 };

  const { rental_campsite: rentalCampsite } = siblings || {};

  const intl = getIntl();

  const tripProtectionPrice = siblingQuotesOrBookingsTotals
    ? siblingQuotesOrBookingsTotals.trip_protection
    : calculateTotalPriceForCategory(
        services,
        rentalCampsite?.services,
        EProtections.TRIP_INSURANCE,
      );

  const roamlyWeatherPrice = siblingQuotesOrBookingsTotals
    ? siblingQuotesOrBookingsTotals.roamly_weather_protection
    : calculateTotalPriceForCategory(
        services,
        rentalCampsite?.services,
        EProtections.WEATHER_PROTECTION,
      );

  const damageProtectionPrice = siblingQuotesOrBookingsTotals
    ? siblingQuotesOrBookingsTotals.damage_protection
    : calculateTotalPriceForCategory(
        services,
        rentalCampsite?.services,
        EProtections.DAMAGE_PROTECTION,
      );

  const roadsideAssistancePrice = siblingQuotesOrBookingsTotals
    ? siblingQuotesOrBookingsTotals.roadside_assistance
    : calculateTotalPriceForCategory(
        services,
        rentalCampsite?.services,
        EServiceTypes.ROADSIDE_ASSISTANCE,
      );

  const isCustomInsurance = (insurance_bundle?.owner_id ?? 0) > 0;
  const isCustomCampsiteInsurance = (rentalCampsite?.insurance_bundle?.owner_id ?? 0) > 0;

  const rental = {
    name: intl.formatMessage(
      {
        defaultMessage:
          '{amount} x {type, select, night {{duration, plural, one {# night} other {# nights}}} day {{duration, plural, one {# day} other {# days}}} other {}}',
        id: 'vmsPPL',
        description:
          'Selectors > Bill > Cost Details > Label for price per day description on bill module.',
      },
      {
        amount: formatCurrency({
          priceInCents: calculated_day_price,
          ...currencyFormat,
        }),
        duration: duration,
        type: priceType,
      },
    ),
    value: formatCurrency({
      priceInCents: rental_amount_item?.total,
      ...currencyFormat,
    }),
  };
  const rentalRV = {
    name: intl.formatMessage(
      {
        defaultMessage:
          'RV - {amount} x {type, select, night {{duration, plural, one {# night} other {# nights}}} day {{duration, plural, one {# day} other {# days}}} other {}}',
        id: 'eOrU19',
        description:
          'Selectors > Bill > Cost Details > Label for price per day description on bill module.',
      },
      {
        amount: formatCurrency({
          priceInCents: calculated_day_price,
          ...currencyFormat,
        }),
        duration: duration,
        type: priceType,
      },
    ),
    value: formatCurrency({
      priceInCents: rental_amount_item?.total,
      ...currencyFormat,
    }),
  };
  const rentalRVsite = {
    name: intl.formatMessage(
      {
        defaultMessage:
          'RV site - {amount} x {type, select, night {{duration, plural, one {# night} other {# nights}}} day {{duration, plural, one {# day} other {# days}}} other {}}',
        id: 'kiVu6M',
        description:
          'Selectors > Bill > Cost Details > Label for price per day description on bill module.',
      },
      {
        amount: formatCurrency({
          priceInCents: rentalCampsite?.calculated_day_price,
          ...currencyFormat,
        }),
        duration: duration,
        type: priceType,
      },
    ),
    value: formatCurrency({
      priceInCents: rentalCampsite?.rental_amount_item?.total,
      ...currencyFormat,
    }),
  };

  const delivery = delivery_item && {
    name: intl.formatMessage({
      defaultMessage: 'Delivery fee',
      id: 'Eut679',
      description: 'Selectors > Bill > Cost Details > Label for delivery fee.',
    }),
    value: formatCurrency({
      priceInCents: delivery_item?.price,
      ...currencyFormat,
    }),
  };

  const getInsuranceInfo = (isCustom: boolean, insuranceBundle: IInsuranceBundle) => {
    return isCustom
      ? intl.formatMessage(
          {
            defaultMessage:
              '{ownerName} has opted to provide their own custom insurance. Please contact {ownerName} for more information',
            id: 'Tg1lok',
            description: "Selectors > Bill > Cost Details > Insurance' tooltip copy.",
          },
          { ownerName },
        )
      : intl.formatMessage(
          {
            defaultMessage:
              'Outdoorsy requires collision and insurance for all rentals. This trip includes {insurance} coverage at {totalPrice}.',
            id: '9PXjkN',
            description: "Selectors > Bill > Cost Details > Insurance' tooltip copy.",
          },
          {
            insurance: insuranceBundle.name,
            totalPrice: formatCurrency({
              priceInCents: insuranceBundle.total_price,
              ...currencyFormat,
            }),
          },
        );
  };

  const insurance = insurance_bundle?.total_price && {
    name: rentalCampsite?.insurance_bundle
      ? intl.formatMessage({ defaultMessage: 'RV - Insurance coverage', id: 'PylsB6' })
      : intl.formatMessage({ defaultMessage: 'Insurance coverage', id: 'yAfzwK' }),
    info: getInsuranceInfo(isCustomInsurance, insurance_bundle),
    value: formatCurrency({
      priceInCents: insurance_bundle.total_price,
      ...currencyFormat,
    }),
  };

  const rvSiteInsurance = rentalCampsite?.insurance_bundle?.total_price && {
    name: intl.formatMessage({ defaultMessage: 'RV site - Insurance coverage', id: 'esr9FX' }),
    info: getInsuranceInfo(isCustomCampsiteInsurance, rentalCampsite.insurance_bundle),
    value: formatCurrency({
      priceInCents: rentalCampsite.insurance_bundle.total_price,
      ...currencyFormat,
    }),
  };

  const tripProtection = tripProtectionPrice && {
    name: intl.formatMessage({ defaultMessage: 'Trip protection', id: 'Si9jYU' }),
    info: intl.formatMessage({
      defaultMessage:
        'Covers you before and during your trip for certain cancellations, interruptions, delays, and medical emergencies.',
      id: 'Kf5pp3',
      description: "Selectors > Bill > Cost Details > Trip protection's tooltip copy.",
    }),
    value: formatCurrency({ priceInCents: tripProtectionPrice, ...currencyFormat }),
  };

  const roamlyWeatherProtection = roamlyWeatherPrice && {
    name: intl.formatMessage({ defaultMessage: 'Weather protection', id: 'ny0tBX' }),
    info: intl.formatMessage({
      defaultMessage: 'You’ll be automatically reimbursed when it rains during your trip dates.',
      id: 'DFYoEu',
      description: "Selectors > Bill > Cost Details > Weather protection's tooltip copy.",
    }),
    value: formatCurrency({ priceInCents: roamlyWeatherPrice, ...currencyFormat }),
  };

  const damageProtection = damageProtectionPrice && {
    name: intl.formatMessage({ defaultMessage: 'Interior coverage', id: 'mj3Jl1' }),
    value: formatCurrency({ priceInCents: damageProtectionPrice, ...currencyFormat }),
  };

  const roadsideAssistance = roadsideAssistancePrice && {
    name: intl.formatMessage({ defaultMessage: 'Roadside Assistance', id: 'Y5DEh8' }),
    value: formatCurrency({ priceInCents: roadsideAssistancePrice, ...currencyFormat }),
    info: intl.formatMessage({
      defaultMessage:
        'Includes 24/7 technical support, towing, jump starts, mobile tire assistance, locksmith service, delivery of fluids, and mobile mechanic.',
      id: 'W4+TEn',
      description: 'Selectors > Bill > Cost Details > Road assistance tooltip copy.',
    }),
  };

  const totalTaxes = siblingQuotesOrBookingsTotals
    ? siblingQuotesOrBookingsTotals.taxes
    : tax + (rentalCampsite?.tax || 0);

  const taxes = totalTaxes && {
    name: intl.formatMessage({
      defaultMessage: 'Est. State & local tax',
      id: 'jPYV4I',
    }),
    info: intl.formatMessage({
      defaultMessage:
        "Due to recent changes in the law, online bookings are now subject to state & local tax. These are not taxes imposed by Outdoorsy - they're the results of legislation enacted by each individual state.",
      id: 'dibYCC',
      description: "Selectors > Bill > Cost Details > Taxes' tooltip copy.",
    }),
    value: formatCurrency({ priceInCents: totalTaxes, ...currencyFormat }),
  };

  const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  const itemTypesWithDescriptionTooltip = ['prep_fee', 'state_fee'];
  const ownerFees =
    owner_fees?.map(({ name, total, description, parent_item_type }) => ({
      name: capitalizeFirstLetter(name),
      info: itemTypesWithDescriptionTooltip.includes(parent_item_type)
        ? description
        : intl.formatMessage({
            defaultMessage:
              'Additional fees charged separately by the host to cover costs like cleaning, prep, and maintenance.',
            id: 'tEhb9a',
            description: "Selectors > Bill > Cost Details > Host fees' tooltip copy.",
          }),
      value: formatCurrency({ priceInCents: total, ...currencyFormat }),
    })) ?? [];

  const rvPetFees =
    owner_fees?.filter(({ parent_item_type }) => parent_item_type === 'pet_fee') ?? [];
  const rvSitePetFees =
    rentalCampsite?.owner_fees?.filter(({ parent_item_type }) => parent_item_type === 'pet_fee') ??
    [];
  const petFeesTotal = siblingQuotesOrBookingsTotals
    ? siblingQuotesOrBookingsTotals.pet_fees
    : [...rvPetFees, ...rvSitePetFees].reduce((acc, curr) => acc + curr.total, 0);
  const petFees = {
    name: intl.formatMessage({ defaultMessage: 'Pet fee', id: 'CSuAxU' }),
    value: formatCurrency({ priceInCents: petFeesTotal, ...currencyFormat }),
    info: intl.formatMessage({
      defaultMessage:
        'Additional fees charged separately by the host to cover costs like cleaning, prep, and maintenance.',
      id: 'tEhb9a',
      description: "Selectors > Bill > Cost Details > Host fees' tooltip copy.",
    }),
  };

  const rvOwnerFees =
    owner_fees
      ?.filter(({ parent_item_type }) => parent_item_type !== 'pet_fee')
      .map(({ name, total, description, parent_item_type }) => ({
        name: capitalizeFirstLetter(name),
        info: itemTypesWithDescriptionTooltip.includes(parent_item_type)
          ? description
          : intl.formatMessage({
              defaultMessage:
                'RV - Additional fees charged separately by the host to cover costs like cleaning, prep, and maintenance.',
              id: 'sSVAg1',
              description: "Selectors > Bill > Cost Details > Host fees' tooltip copy.",
            }),
        value: formatCurrency({ priceInCents: total, ...currencyFormat }),
      })) ?? [];

  const rvSiteOwnerFees =
    rentalCampsite?.owner_fees
      ?.filter(({ parent_item_type }) => parent_item_type !== 'pet_fee')
      .map(({ name, total, description, parent_item_type }) => ({
        name: capitalizeFirstLetter(name),
        info: itemTypesWithDescriptionTooltip.includes(parent_item_type)
          ? description
          : intl.formatMessage({
              defaultMessage:
                'RV site - Additional fees charged separately by the host to cover costs like cleaning, prep, and maintenance.',
              id: 'mYvsNW',
              description: "Selectors > Bill > Cost Details > Host fees' tooltip copy.",
            }),
        value: formatCurrency({ priceInCents: total, ...currencyFormat }),
      })) ?? [];

  const insuranceTotal =
    (insurance_bundle?.total_price || 0) + (rentalCampsite?.insurance_bundle?.total_price || 0);
  const campsiteFee = rentalCampsite?.service_fee || 0;

  const serviceFee = siblingQuotesOrBookingsTotals
    ? siblingQuotesOrBookingsTotals.service_fees
    : service_fee + campsiteFee + (isStay ? insuranceTotal : 0);

  const service = {
    name: intl.formatMessage({ defaultMessage: 'Service fee', id: 'G0bXT7' }),
    info: isStay
      ? intl.formatMessage({
          defaultMessage:
            'Helps us run our platform, provide insurance for your stay, and give you 24 / 7 customer support.',
          id: 'rAwxLf',
          description: "Selectors > Bill > Cost Details > Service's tooltip copy.",
        })
      : intl.formatMessage({
          defaultMessage:
            'Helps us keep the lights on and gives you access to our 24/7 customer support team.',
          id: 'kVFEVF',
          description: "Selectors > Bill > Cost Details > Service's tooltip copy.",
        }),
    value: formatCurrency({
      priceInCents: serviceFee,
      ...currencyFormat,
    }),
  };

  const defaultValue = formatCurrency({ priceInCents: undefined, ...currencyFormat });

  const generator = generatorRules && {
    name: capitalizeFirstLetter(generatorRules.title),
    description: [generatorRules?.subTitle, generatorRules.tiers]
      .flat()
      .filter(x => x !== undefined)
      .join('\n'),
    value: generatorRules?.free ? defaultValue : '',
  };

  // TODO: figure out how to render mileage
  const mileage = mileageRules && {
    name: mileageRules.title,
    description: [mileageRules?.subTitle, mileageRules.tiers]
      .flat()
      .filter(x => x !== undefined)
      .join('\n'),
    value: defaultValue,
  };

  const addonsTitle = {
    title: intl.formatMessage({
      defaultMessage: 'Add-Ons',
      id: 'FPoa2t',
      description: 'Selectors > Bill > Cost Details > Add-Ons section title',
    }),
  };

  const getAddonsInfo = (isDaily: boolean) => {
    return isDaily
      ? intl.formatMessage({
          defaultMessage: 'per day',
          id: '38DaVi',
          description: 'Selectors > Bill > Cost Details > Addons daily text',
        })
      : intl.formatMessage({
          defaultMessage: 'each',
          id: 'ZUXXeq',
          description: 'Selectors > Bill > Cost Details > Addons info for price text',
        });
  };

  const getAddonsValue = (deferred: boolean, total: number, formattedAmount: string) => {
    if (deferred) {
      return intl.formatMessage({ defaultMessage: 'Paid on return', id: 'BVR6Jx' });
    }
    if (total === 0) {
      return intl.formatMessage({ defaultMessage: 'FREE', id: '7GOXli' });
    }
    return formattedAmount;
  };

  const addons =
    items
      ?.filter(({ parent_item_id, count, required }) => parent_item_id && count && !required)
      .map(({ name, total, description, count, daily, price, deferred }) => {
        const formattedAmount = formatCurrency({ priceInCents: total, ...currencyFormat });
        const formattedPrice = formatCurrency({ priceInCents: price, ...currencyFormat });
        const formattedName = intl.formatMessage(
          {
            defaultMessage:
              '{name} x {count} {hasPrice, select, true {({formattedPrice} {info})} other {}}',
            id: 'xERhS3',
            description: 'Selectors > Bill > Cost Details > Addons detail',
          },
          {
            name,
            count,
            formattedPrice,
            hasPrice: !!price,
            info: getAddonsInfo(daily),
          },
        );
        return {
          name: formattedName,
          description,
          value: getAddonsValue(deferred, total, formattedAmount),
        };
      }) ?? [];

  const rvAddons =
    items
      ?.filter(({ parent_item_id, count, required }) => parent_item_id && count && !required)
      .map(({ name, total, description, count, daily, price, deferred }) => {
        const formattedAmount = formatCurrency({ priceInCents: total, ...currencyFormat });
        const formattedPrice = formatCurrency({ priceInCents: price, ...currencyFormat });
        const formattedName = intl.formatMessage(
          {
            defaultMessage:
              'RV - {name} x {count} {hasPrice, select, true {({formattedPrice} {info})} other {}}',
            id: 'w3pu+b',
            description: 'Selectors > Bill > Cost Details > Addons detail',
          },
          {
            name,
            count,
            formattedPrice,
            hasPrice: !!price,
            info: getAddonsInfo(daily),
          },
        );
        return {
          name: formattedName,
          description,
          value: getAddonsValue(deferred, total, formattedAmount),
        };
      }) ?? [];

  const rvSiteAddons =
    rentalCampsite?.items
      ?.filter(({ parent_item_id, count, required }) => parent_item_id && count && !required)
      .map(({ name, total, description, count, daily, price, deferred }) => {
        const formattedAmount = formatCurrency({ priceInCents: total, ...currencyFormat });
        const formattedPrice = formatCurrency({ priceInCents: price, ...currencyFormat });
        const formattedName = intl.formatMessage(
          {
            defaultMessage:
              'RV site - {name} x {count} {hasPrice, select, true {({formattedPrice} {info})} other {}}',
            id: 'E6du8+',
            description: 'Selectors > Bill > Cost Details > Addons detail',
          },
          {
            name,
            count,
            formattedPrice,
            hasPrice: !!price,
            info: getAddonsInfo(daily),
          },
        );
        return {
          name: formattedName,
          description,
          value: getAddonsValue(deferred, total, formattedAmount),
        };
      }) ?? [];

  const sectionDivider = { divider: true };

  // RAF discount will be applied as credits when approved,
  // until then, show it as a separate discount.
  const rafCode = 'raf_waiver_summary' in data &&
    data.raf_waiver_summary && {
      name: intl.formatMessage(
        { defaultMessage: 'Referral code {code}', id: 'doRGHs' },
        { code: data.raf_waiver_summary.referral_code },
      ),
      value: formatCurrency({
        priceInCents: data.raf_waiver_summary.referee_amount,
        ...currencyFormat,
      }),
      isDiscount: true,
    };

  const promoCode = discount_code && {
    name: intl.formatMessage(
      { defaultMessage: 'Promo code {code}', id: 'ncTC4x' },
      { code: discount_code },
    ),
    value: formatCurrency({ priceInCents: discount_code_amount, ...currencyFormat }),
    isDiscount: true,
  };

  const tripCredits = !!renter_credits_applied &&
    showTripCredits && {
      name: intl.formatMessage({ defaultMessage: 'Trip credits', id: 'ACL+4x' }),
      value: formatCurrency({ priceInCents: renter_credits_applied, ...currencyFormat }),
      isDiscount: true,
    };

  const discounts = [rafCode, promoCode, tripCredits].filter(Boolean);

  const costs = [
    ...(rentalCampsite ? [rentalRV, rentalRVsite] : [rental]),
    isStay ? false : delivery,
    isStay ? false : insurance,
    isStay ? false : rvSiteInsurance,
    tripProtection,
    roamlyWeatherProtection,
    isStay ? false : damageProtection,
    roadsideAssistance,
    taxes,
    rentalCampsite && petFeesTotal > 0 ? petFees : false,
    ...(rentalCampsite ? rvOwnerFees : ownerFees),
    ...rvSiteOwnerFees,
    service,
    isStay ? false : mileage,
    isStay ? false : generator,
    (rentalCampsite && (rvAddons.length || rvSiteAddons.length)) ||
    (!rentalCampsite && addons.length)
      ? addonsTitle
      : null,
    ...(rentalCampsite ? rvAddons : addons),
    ...(rentalCampsite ? rvSiteAddons : []),
    discounts.length ? sectionDivider : null,
    ...discounts,
  ].filter(Boolean) as ICost[];

  return {
    costs,
    total: formattedTotal,
  };
};

export const getBookingOrQuoteCostDetails = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TRootState['quote']['data'],
  TRootState['auth']['user'],
  ReturnType<typeof getBookingOrQuoteTotal>,
  ReturnType<typeof getOwnerName>,
  ReturnType<typeof getListingMileageRules>,
  ReturnType<typeof getListingGeneratorRules>,
  ReturnType<typeof getListingPriceType>,
  boolean,
  ICostDetails | null
>(
  state => state.checkout.booking,
  state => state.quote.data,
  state => state.auth.user,
  getBookingOrQuoteTotal,
  getOwnerName,
  state => getListingMileageRules(state, false),
  getListingGeneratorRules,
  getListingPriceType,
  state => state.listing.data?.rental_category === 'stay',
  (
    booking,
    quote,
    user,
    formattedTotal,
    ownerName,
    mileageRules,
    generatorRules,
    priceType,
    isStay,
  ) => {
    return mapDataToCostDetails({
      data: booking || quote,
      formattedTotal,
      ownerName,
      mileageRules,
      generatorRules,
      priceType,
      showTripCredits: !booking?.trip_credits_disabled && !!user?.credits,
      isStay,
    });
  },
);
